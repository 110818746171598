<template>
    <div class="quote p-3 mb-4 pb-0">
        <h6>
            Rapporté par
            <user-name :user="citation.rapporteur"/>
            le {{ formatDate(citation.dateRapport) }}
            <text-edit-button v-if="canEdit" @click.native="editCitation" class="ml-2"/>
            <text-delete-button v-if="canEdit" @click.native="deleteCitation" class="ml-1"/>
        </h6>
        <hr class="mt-3 mb-3"/>
        <blockquote>
            <span v-html="sanitize(citation.texte)"></span>
            <footer>
                <font-awesome-icon :icon="['far', 'comment']" class="ml-3 mr-2"/>
                <user-name v-if="citation.auteur" :user="citation.auteur"/>
                {{ citation.auteurString || '' }}
                <span v-if="isInTwoParts"> &mdash; </span>
                {{ citation.dateOrigine ? formatDate(citation.dateOrigine) : '' }}
            </footer>
        </blockquote>
    </div>
</template>

<script>
    import {sanitize} from 'dompurify';

    import {formatDate} from '@/util/date';
    import {canEdit}    from '@/util/citation';
    import {apiPath}    from '@/util/http';
    import alert        from '@/util/alert';

    const TextEditButton    = () => import('./text_button/TextEditButton');
    const TextDeleteButton  = () => import('./text_button/TextDeleteButton');
    const UserName          = () => import('./UserName');
    const EditCitationModal = () => import("@/components/modals/EditCitationModal");

    export default {
        name: "Citation",
        components: {TextEditButton, TextDeleteButton, UserName},
        props: {
            citation: {
                required: true
            }
        },
        methods: {
            sanitize,
            formatDate,
            editCitation() {
                this.$store.dispatch('modal/create', {
                    component: EditCitationModal,
                    props: {citation: this.citation, callback: () => this.$emit('edit')}
                });
            },
            deleteCitation() {
                alert.confirm('Supprimer cette citation ?', () => {
                    alert.loading();
                    this.axios.delete(apiPath('delete_citation', {citation: this.citation.id}))
                        .then(() => this.$emit('edit'))
                        .catch(() => this.$toaster.error('Impossible de supprimer la citation'))
                        .finally(alert.stopLoading);
                });
            }
        },
        computed: {
            isInTwoParts() {
                return (this.citation.auteur || this.citation.auteurString) && this.citation.dateOrigine;
            },
            canEdit() {
                return canEdit(this.citation);
            }
        }
    }
</script>

<style scoped>
    div.quote {
        border-radius: .5rem;
        box-shadow: 0 0 .66rem rgba(0, 0, 0, 0.2);
    }

    h6 {
        font-weight: 300;
        font-size: small;
        color: #383838;
    }
</style>